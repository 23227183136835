.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-footerSpace {
    padding-bottom: 88px;
}

.App-brandSecondaryColor {
    color: #70B9BE;
}

html, body, #root, .App, .transition-h100 {
    height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-noVisible {
    display: none !important;
}

.QuizHeader-headText {
    font-family: 'Roboto', serif;
    font-size: 28px;
    color: #0A2533;
}

.QuizHeader-brandLogo {
    font-family: 'Roboto', serif;
    font-weight: bold;
    font-size: 16px;
    color: #042628;
    text-decoration: none;
}

.QuizHeader-counter {
    font-family: 'Roboto', serif;
    font-size: 14px;
    color: #5C5C5C;
}

.dots-flashing {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
    0% {
        box-shadow: 20px 0 #70B9BE, -20px 0 #0002;
        background: #70B9BE
    }
    33% {
        box-shadow: 20px 0 #70B9BE, -20px 0 #0002;
        background: #0002
    }
    66% {
        box-shadow: 20px 0 #0002, -20px 0 #70B9BE;
        background: #0002
    }
    100% {
        box-shadow: 20px 0 #0002, -20px 0 #70B9BE;
        background: #70B9BE
    }
}

.secondary-background-color {
    background: #F0F6F4;
}

.brand-secondary-color {
    color: #70B9BE;
}

.withoutExtPadding {
    margin: 0 -16px;
}

.pw-secondaryBlock {
    margin: 0 -16px;
    background-color: #F0F6F4;
    border-radius: 16px;
}